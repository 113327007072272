import React, { useCallback, useState } from 'react';

import { compose, withProps, withState } from 'recompose';
import SwipeableViews from 'react-swipeable-views';

import moment from 'moment'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { virtualize } from 'react-swipeable-views-utils';
import ExtractList from './ExtractList';
import { Paper, ButtonBase, List, Button } from "@material-ui/core";
import { translate } from 'react-i18next';
import ExportExtractForm from './components/ExportExtractForm/ExportExtractForm';
import Select from '../../components/FormsV2/Select';
import classNames from 'classnames';
import { getCurrencyBySymbol } from '../../models/Currency';
import { styles } from './styles'
import AccountListDateField from '../Profile/Account/AccountListDateField';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux'
import validate from '../../utils/validate';
import { debounce } from 'lodash';
import { addSnack, fetchExtract } from '../../redux/actions';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const BRL_CURRENCIES = ['REALT', 'CBRL', 'BRL', 'BRZ', 'RAS']
const filterExtracts = (extracts, operationType, currencyType) => {
  return extracts.filter(function (item) {
    const hasMatch = operationType !== 'ALLOPERATIONS' ? new RegExp(operationType) : ''
    if(currencyType === "BRL" && BRL_CURRENCIES.includes(item.currency) && item.type.match(hasMatch)){
      return true
    }
    else if((item.currency === currencyType || currencyType === 'ALL') && item.type.match(hasMatch)){ 
      return true
    }
  })
}
const slideRenderer = (extractOptions, tabIndex, extractOperationsValue, extracts) => (params) => {
  const { key } = params;
  const currencyType = extractOptions[key];
  const operationType = extractOperationsValue;
  const listComponentKey = `${currencyType}Extracts`;
  const isToShowAll = currencyType === 'ALL' && operationType === 'ALLOPERATIONS'
    return (<ExtractList key={listComponentKey} extracts={
      isToShowAll ? extracts : filterExtracts(extracts, operationType, currencyType)
    } />);
};

const schemas = {
  from: {
    type: String,
    required: true
  },
  to: {
    type: String,
    required: true
  }
};

const Extract = (
  { extracts,
    tabIndex,
    tabIndexOperations,
    setTabIndex,
    setTabIndexOperations,
    classes,
    t,
    extractOptions,
    extractOperations,
    anchorEl,
    setAnchorEl,
    currencies,
    addSnack,
    fetchExtractsByPeriod,
  }) => {

const [currencyToFilter, setCurrencyToFilter] = useState(null);

 const selectCurrencyToExtractFilter = (currency) => {
    setCurrencyToFilter(currency)
    setTabIndex(extractOptions.indexOf(currency ? currency.symbol : 'ALL'));
 }

 const selectCurrencyToExtractByIndex = (index) => {
  setTabIndex(index)
  const currency = extractOptions[index]
  setCurrencyToFilter(currency === "ALL" ? null : getCurrencyBySymbol(currencies, currency))
 }

 const resetFilter = () => {
  setCurrencyToFilter(null)
  setTabIndex(0)
  }

  const debouncedFetchExtractsByPeriod = useCallback(debounce(({from, to}) => {
    if (!moment(from).isValid() || !moment(to).isValid()) {
      addSnack({ message: t('screens.extract.invalidExtractDates') })
      return;
    }

    if (moment(to).isBefore(moment(from))) {
      addSnack({ message: t('screens.extract.invalidBeforeAndAfterDates') })
      return;
    }

    fetchExtractsByPeriod({ from, to });
  }, 500), []);

  const coinsSelectorExtraData = {
    hasStableCoin: true,
    customActionOnClick: selectCurrencyToExtractFilter,
    disableHighlight: true,
    hasFiat: true,
  }

  return (
      <div style={{paddingBottom: 5}}>

        <Paper square elevation={0}>
          <ExportExtractForm
          />
        </Paper>
        <Paper square elevation={0} >
        <div className={classes.optionsContainer}>
          <form onSubmit={(event) => {
              const form = new FormData(event.target);
              const from = moment(form.get('from'), 'DD/MM/YYYY').format('YYYY-MM-DD');
              const to = moment(form.get('to'), 'DD/MM/YYYY').format('YYYY-MM-DD');
              event.preventDefault();
              debouncedFetchExtractsByPeriod({from, to});
            }}>
              <List className={classes.field}>
                <AccountListDateField
                    label={t('screens.extract.from')}
                    name='from'
                    canEdit={true}
                    minDate={moment('2018-08-01')}
                    maxDate={moment().toDate()}
                />
                <AccountListDateField
                    label={t('screens.extract.to')}
                    name='to'
                    canEdit={true}
                    minDate={moment('2018-08-01')}
                    maxDate={moment().toDate()}
                />
                <Button
                  className={classes.submitButton}
                  type='submit'
                  color='secondary'
                  variant='raised'
                  style={{marginBottom:'15px'}}
                >     
                  Carregar Extrato
                </Button>
              </List>
            </form>
            <div className={classNames(classes.row, classes.containerSelects)}>
              <div className={classNames(classes.selectContent)}>
                <div className={classes.selectContentCurrency}> <Typography variant="body2">{t('transactions.selectExtractCoin')}</Typography>
                {currencyToFilter !== null && (
                  <ButtonBase className={classes.resetFilterButton} onClick={resetFilter}>{t("screens.extract.showAllCoins")}</ButtonBase>)}
                </div> 
                <Select
                  className={classes.selectButton}
                  value={currencyToFilter}
                  placeholder={t(`transactions.${extractOptions[tabIndex]}`)}
                  customHandleClick={(e) =>
                    anchorEl
                      ? setAnchorEl(null)
                      : setAnchorEl(e.currentTarget, true, coinsSelectorExtraData)
                  }
                  getLabel={option => option ?  t(`transactions.${option.symbol}`, {defaultValue: option.symbol}) : t('transactions.all')}
                />
              </div>
              <div className={classes.selectContent}>
                <Typography variant="body2">{t('transactions.selectExtractType')}</Typography>
                <Select
                  className={classes.selectButton}
                  value={extractOperations[tabIndexOperations]}
                  options={extractOperations}
                  placeholder={t(`transactions.${extractOperations[tabIndexOperations].toLowerCase()}.title`)}
                  onChange={e => {
                    setTabIndexOperations(extractOperations.indexOf(e));
                  }}
                  getLabel={option => t(`transactions.${option.toLowerCase()}.title`)}
                />
              </div>
            </div>
            
          </div>
        </Paper>
        {
          extracts.length===0 ? (
            <Paper className={classes.paper}>
              <div className={classes.nothingToShow}>
                <Typography variant="subheading" color="textSecondary">
                  {t('info.emptyHistory')}
                </Typography>
              </div>
            </Paper>
            ) :             
            (
              <VirtualizeSwipeableViews
                slideCount={extractOptions.length}
                index={tabIndex}
                onChangeIndex={value => selectCurrencyToExtractByIndex(value)}
                axis="x"
                slideRenderer={slideRenderer(extractOptions, tabIndex, extractOperations[tabIndexOperations], extracts)}
              />
            )
        }
      </div>
  );
};

const mapDispatchToProps = dispatch => ({
  addSnack: (message) => dispatch(addSnack(message)),
  fetchExtractsByPeriod: (form) => dispatch(fetchExtract(form)),
});

const enhance = compose(
  withState('tabIndex', 'setTabIndex', 0),
  withState('tabIndexOperations', 'setTabIndexOperations', 0),
  withStyles(styles),
  translate(),
  connect(null, mapDispatchToProps),
  withProps({
    initialValues: {
      from: moment()
        .subtract(3, 'month')
        .toDate(),
      to: moment()
        .toDate(),
    }
  }),
  reduxForm({
    validate: validate(schemas),
    form: 'extractPeriodFilter',
    enableReinitialize: true,
  })
);

export default enhance(Extract);
