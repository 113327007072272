import { compose, withHandlers, withState } from 'recompose'
import { withFormik, yupToFormErrors } from 'formik'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import {
  ORDER_TYPES,
} from '../../../Trade/TradeOrderForm/constants';
import withTradePercentageInputHandlers from '../../../Trade/TradeOrderForm/withTradePercentageInputHandlers'
import transactionsActions from '../../../../redux/transactions/actions'
import {
  setSelectedMarket,
  addDialog,
  getQuote,
  executeQuote,
  handleAppBarMenu,
} from '../../../../redux/actions'
import { toFixedNoRound } from '../../../../utils/toFixedNoRound';
import { updateFormData } from '../../../../redux/layout/transactionsForm/actions';
import { getCurrencyBySymbol } from '../../../../models/Currency';
import { MarketInfo } from '../../../../models/MarketInfo';

const canRequestQuote = (formData) => {
  const { value, maxLimit, minLimit } = formData;
  return value >= minLimit && value <= maxLimit;
}

const getExpressTradeFormInputHandler =
  (inputName) =>
    ({ setFieldValue, ...props }) =>
      (inputValue) => {
        const {
          orderType,
          market,
          getQuote,
          setLastInput,
          timer,
          setTimer,
          setLoader,
          setKeyCounter,
          setPlayCounter,
          marketInfo
        } = props;
        const handleQuote = (formData) => {
          const {quoteType, value, maxLimit, minLimit} = formData;
          setLoader(true);
          setTimer(clearTimeout(timer));
          setTimer(
            setTimeout(() => {
              const hasLimits = maxLimit !== null && minLimit != null;
              if (hasLimits ? !canRequestQuote(formData) : false) {
                setLoader(false);
                return;
              }
              getQuote(quoteType, value, orderType, market);
            }, 2000)
          );
          setKeyCounter((prevKey) => prevKey + 1);
          setPlayCounter(false);
        };

        setLastInput(inputName);
        setFieldValue(inputName, inputValue);
        setFieldValue("amountPercentage", undefined);
        setFieldValue("totalValuePercentage", undefined);
        const isInputTotalValue = inputName === "totalValue";

        if (inputValue > 0) {
          const minLimit = isInputTotalValue ? marketInfo?.rfqMinQuoteAmount : marketInfo?.rfqMinBaseAmount;
          const maxLimit = isInputTotalValue ? marketInfo?.rfqMaxQuoteAmount : marketInfo?.rfqMaxBaseAmount;

          const quoteType = isInputTotalValue ? "quote_amount" : "base_amount";

          handleQuote({
            quoteType: quoteType,
            value: inputValue,
            minLimit,
            maxLimit
          });

        } else {
          const oppositeField = isInputTotalValue ? "amount" : "totalValue";
          setFieldValue(oppositeField, inputValue);
        }
      };

const mapStateToProps = ({ market, user: { balance, limits, profile }, orders, credentials, layout, currencies }) => {
  const currencyMarket = market.selectedMarket;
  const markets = currencies.markets;
  const marketInfo = new MarketInfo(markets[currencyMarket]);

  return ({
    market: currencyMarket,
    userEmail: profile.email,
    kycLevel: profile.kyc_level,
    taxID: profile.tax_id,
    userId: profile.id,
    balance,
    limits,
    buyOrdersSerialized: orders.buyOrdersSerialized,
    sellOrdersSerialized: orders.sellOrdersSerialized,
    quoteValues: orders.RFQ,
    token: credentials.token,
    data: layout.transactionsForm.formData,
    currencies: currencies.currencies,
    markets,
    anchorEl: layout.appBar.anchorEl,
    marketInfo,

  })};
const mapDispatchToProps = dispatch => ({
  hasNoWithdrawalOrDepositWarning: (currencyName) => dispatch(addDialog({
    title: 'common.attention',
    renderComponent: 'hasNoWithdrawalOrDepositWarning',
    componentProps: {
      currencyName,
    },
    availableChoices: [
      {
        label: 'common.understood',
        color: 'secondary',
        variant: 'raised',

      }
    ]
  })),
  executeQuote: (quoteId) => dispatch(executeQuote(quoteId)),
  updateForm: (values) => dispatch(updateFormData(values)),
  getQuote: (param, inputValue, orderType, market) => dispatch(getQuote(param, inputValue, orderType, market)),
  createOrder: (order, props) => dispatch(transactionsActions.createOrder(order, props)),
  setSelectedMarket: market => dispatch(setSelectedMarket(market)),
  setAnchorEl: (anchorEl) => dispatch(handleAppBarMenu(anchorEl, true)),
  warningCripto: (info) => {
    dispatch(
      addDialog({
        title: (info),
        availableChoices: [
          {
            label: 'common.understood',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised',
          },
        ],
        actionsToTake: {
          relocationPage: () => window.location.href = '/login'
        }
      })
    )
  },
})

const TradeExpressSchema = Yup.object().shape({
  amount: Yup.number()
    .required("REQUIRED")
    .when(
      ["$minBaseAmountLimit", "$marketSymbol", "$lastInput"],
      (minBaseAmountLimit, marketSymbol, lastInput, schema) => {
        return lastInput === "amount"
          ? schema.min(minBaseAmountLimit, {
            error: "BELOW_MINIMUM_AMOUNT_FIELD",
            min: minBaseAmountLimit,
            symbol: marketSymbol,
          })
          : schema;
      }
    )
    .when(
      ["$maxBaseAmountLimit", "$lastInput"],
      (maxBaseAmountLimit, lastInput, schema) => {
        return lastInput === "amount" && maxBaseAmountLimit > 0
          ? schema.max(maxBaseAmountLimit, {
            error: "ABOVE_MAX_AMOUNT",
          })
          : schema;
      }
    )
    // When orderType is SELL, balance must be BTC
    .when("$orderType", {
      is: ORDER_TYPES.SELL,
      then: Yup.number().when("$balance", (balance, schema) =>
        schema.max(balance, "NOT_ENOUGH_BALANCE")
      ),
    }),

  totalValue: Yup.number()
    .required("REQUIRED")
    .positive("BELOW_MINIMUM_AMOUNT")
    .when(
      ["$minQuoteAmountLimit", "$lastInput"],
      (minQuoteAmountLimit, lastInput, schema) => {
        return lastInput === "totalValue"
          ? schema.min(minQuoteAmountLimit, {
            error: "BELOW_MINIMUM_AMOUNT_FIELD",
            min: minQuoteAmountLimit.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "BRL",
            }),

            symbol: "",
          })
          : schema;
      }
    )
    .when(
      ["$maxQuoteAmountLimit", "$lastInput", "$orderType", "$balance"],
      (maxQuoteAmountLimit, lastInput, orderType, balance, schema) => {
        const isTotalValue = lastInput === "totalValue";
        const isOrderTypeBuy = orderType === ORDER_TYPES.BUY;
        const maxQuoteAmountLimitHasValue = maxQuoteAmountLimit > 0;
        const isRestockBalance = isOrderTypeBuy && balance < maxQuoteAmountLimit;

        if ((isTotalValue && maxQuoteAmountLimitHasValue) || isRestockBalance) {

          let errorMessage = "ABOVE_MAX_AMOUNT";
          let maxValueToUse = maxQuoteAmountLimit;

          if (isRestockBalance) {
            maxValueToUse = balance;
            errorMessage = "TO_RESTOCK_BALANCE";
          }
          return schema.max(maxValueToUse, {
            error: errorMessage,
          });
        } else {
          return schema;
        }
      }
    ),
});

const withExpressTradeForm = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('lastInput', 'setLastInput', 'totalValue'),
  withFormik({
    enableReinitialize: false,
    mapPropsToValues: ({ market }) => ({
      paymentMethod: 'BALANCE',
      currency: market.split('-')[0],
      market: market,
      totalValue: undefined,
      amount: undefined,
      price: undefined,
      amountPercentage: undefined,
      totalValuePercentage: undefined,
      isMarketPriceSelected: true, // constant,
      currencyConfig: undefined
    }),
    validate: (values, props) => {
      const { orderType, marketInfo, lastInput } = props;
      const marketSymbol = values.currency;
      const minBaseAmountLimit = marketInfo?.rfqMinBaseAmount ?? 0;
      const minQuoteAmountLimit = marketInfo?.rfqMinQuoteAmount ?? 0;
      const maxBaseAmountLimit = marketInfo?.rfqMaxBaseAmount ?? 0;
      const maxQuoteAmountLimit = marketInfo?.rfqMaxQuoteAmount ?? 0;

      const balance = props.orderType.toUpperCase() === 'BUY'
        ? props.balance['BRL']
        : props.balance[values.currency]

      return TradeExpressSchema
        .validate(values, {
          abortEarly: false,
          context: {
            balance,
            orderType,
            marketSymbol,
            minBaseAmountLimit,
            minQuoteAmountLimit,
            maxBaseAmountLimit,
            maxQuoteAmountLimit,
            lastInput
          }
        })
        .catch(err => {
          throw yupToFormErrors(err)
        })
    },
    clearForm:(formikBag) => {
      const {resetForm} = formikBag
      resetForm();
    }
  }),
  withTradePercentageInputHandlers,
  withState('timer', 'setTimer', undefined),
  withState('loader', 'setLoader', false),
  withState('playCounter', 'setPlayCounter', false),
  withState('keyCounter', 'setKeyCounter', 0),
  withState('duration', 'setDuration', 0),
  withHandlers({
    handleExpressTotalValuePercentChange: props => percent => {
      const {
        setFieldValue,
        balance,
        orderType,
        market,
        setKeyCounter,
        setLoader,
        setTimer,
        timer,
        setPlayCounter,
        getQuote,
        setLastInput
      } = props;
      const totalValue = balance.BRL * (percent / 100);
      if(totalValue > 0){
        setLastInput('totalValue');
        setLoader(true);
        setTimer(clearTimeout(timer));
        setTimer(setTimeout(() => { getQuote("quote_amount", totalValue, orderType, market) }, 2000));
        setKeyCounter(prevKey => prevKey+1);
        setPlayCounter(false);
        setFieldValue('totalValue', toFixedNoRound(totalValue, 2));
      }
      setFieldValue('totalValuePercentage', percent);
      setFieldValue('totalValue', toFixedNoRound(totalValue, 2));
    },
    handleExpressAmountPercentChange: props => percent => {
      const {
        setFieldValue,
        balance,
        orderType,
        market,
        setKeyCounter,
        setLoader,
        setTimer,
        timer,
        setPlayCounter,
        getQuote,
        setLastInput,
        currencies
      } = props;
      const currencyConfig = getCurrencyBySymbol(currencies, market.split("-")[0]);

      if (currencyConfig) {
        const amount = (balance[currencyConfig.symbol] || 0) * (percent / 100);
        if (amount > 0) {
          setLastInput("amount");
          setLoader(true);
          setTimer(clearTimeout(timer));
          setTimer(
            setTimeout(() => {
              getQuote("base_amount", amount, orderType, market);
            }, 2000)
          );
          setKeyCounter((prevKey) => prevKey + 1);
          setPlayCounter(false);
        }
        setFieldValue("amountPercentage", percent);
        setFieldValue("amount", amount);
      }
    },
    handleValueChange: getExpressTradeFormInputHandler('totalValue'),
    handleAmountChange: getExpressTradeFormInputHandler('amount'),
  })
)

export default withExpressTradeForm
