import React from 'react'
import * as R from 'ramda'
import { connect, getIn } from 'formik'
import SubmitButton from '../../../components/Forms/SubmitButton'


const WrappedSubmitButton = props => {
 
  const blockUserSubmit = props.blockUserSubmit;
  const errors = getIn(props.formik.errors)
  const isValid = blockUserSubmit ? false : R.isEmpty(errors)

  return (<SubmitButton disabled={!isValid} {...props} />)
}

export default connect(WrappedSubmitButton)
