
import LinkButton from '../../../components/Buttons/LinkButton'
import React from 'react'

export const DepositButton = (props) => {
    return <LinkButton
    to={'/bankTransfers/deposit'}
    variant='raised'
    className={[
      props.classes.textField,
      props.classes.depositButton,
      props.classes.mobileButton,
      ]}
    
    >
      {props.t('TO_DEPOSIT')}
    </LinkButton>;
};