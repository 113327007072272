import React, { useEffect, useState } from "react";
import { reduxForm, formValueSelector, change } from "redux-form";

import { connect } from "react-redux";
import {
  compose,
  withProps,
  withState,
  withHandlers,
  lifecycle,
} from "recompose";
import { translate } from "react-i18next";

import QRCode from "qrcode.react";
import * as R from "ramda";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FieldRow from "../../components/Forms/FieldRow";
import {
  addDialog,
  setSelectedMarket,
  handleAppBarMenu,
  addSnack
  
} from "../../redux/actions";
import isNilOrEmpty from "../../utils/isNilOrEmpty";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  USDT,
  BTC,
  BSC,
  SOL,
  USDC,
} from "../../config";
import Typography from "@material-ui/core/Typography";
import SelectItemChip from "../../components/SelectItemChip";
import Select from "../../components/FormsV2/Select";
import LimitsIndicator from "../../components/LimitsIndicator";
import CircularProgress from "@material-ui/core/CircularProgress";
import ActivityIndicator from "../../components/ActivityIndicator";

import { FIREBASE_CURRENCIES_ISSUES } from '../../config'
import { getCurrencyIssuesFoundBySymbolAndScreen } from '../../utils/firebaseUtils'
import { CurrencyIssue } from '../../models/CurrencyIssue'

import {
  Currency,
  getCriptoCurrencies,
  getCurrencyBySymbol,   
} from "../../models/Currency";
import fetchWalletAddress from "../../services/cbtcService/fetchWalletAddress";
import { Grid } from "@material-ui/core";
import InfoTooltip from "../../components/InfoTooltip";

const styles = (theme) => ({
  container: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "100%",
    marginTop: 3 * theme.spacing.unit,
    paddingLeft: 3 * theme.spacing.unit,
    paddingRight: 3 * theme.spacing.unit,
  },
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "100%",
    paddingLeft: 3 * theme.spacing.unit,
    paddingRight: 3 * theme.spacing.unit,
  },
  pointer: {
    cursor: "pointer",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
    justifyContent: "center",
    flexWrap: "nowrap",
  },
  radioItem: {
    width: "40%",
    marginLeft: "3%",
    marginRight: 0,
  },
  warningMessage: {
    marginBottom: 10,
    alignSelf: "center",
    color: "#212121",
  },
  networkHeader: {
    alignSelf: "center",
    marginTop: 10,
  },
  walletCopiedState: {
    userSelect: "none",
    color: "red",
    padding: 3,
  },
  currencyIssueDeposit: {
    margin: "0.5rem auto",
  }
});
const ETH = "ETH";

const copyToClipBoard = async (value) => {
  try {
    await navigator.clipboard.writeText(value);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const CopyWallet = ({
  t,
  setCopied,
  onCopyWalletAddress,
  walletAddress,
  label,
  hideWarning,
}) => {
  return (
    <Button
      onClick={() => {
        const isCopySuccess = copyToClipBoard(walletAddress);
        if (isCopySuccess) {
          setCopied(true);
          if (!hideWarning) onCopyWalletAddress(walletAddress);
        }
      }}
      style={{ backgroundColor: "gray", color: "white", margin: 10 }}
    >
      {t(label)}
    </Button>
  );
};

const getWithMemoAddressParts = (fullAddress) => {
  const ADDRESS_INDEX = 0;
  const MEMO_INDEX = 1;
  const splitedParts = fullAddress.split("|");
  return {
    address: splitedParts[ADDRESS_INDEX],
    memo: splitedParts[MEMO_INDEX],
  };
};

const ReceiveCoins = (props) => {
  const {
    hideBalance,
    hideCurrencyRadio,
    hideCurrencySelect,
    classes,
    t,
    currency,
    network,
    balance,
    wallet,
    setWallet,
    copied,
    setCopied,
    onCopyWalletAddress,
    agreeSendOnlyBTC,
    setAgreeSendOnlyBTC,
    agreeUSDTOnlyERC20,
    agreeUSDCOnlySOL,
    setAgreeUSDTOnlyERC20,
    setAgreeUSDCOnlySOL,
    currencyList,
    handleCurrencyChange,
    limitsDeposit,
    minimumCurrencyDeposit,
    setMinimumCurrencyDeposit,
    isOnlyBlockchainAda,
    isOnlyBlockchainSol,
    isOnlyBlockchainBep20,
    isOnlyBlockchainErc20,
    isOnlyBlockchainLuna,
    handleBlockchainChange,
    blockchainOptions,
    blockchainLabel,
    setBlockchainLabel,
    networkInUse,
    walletRequested,
    setWalletRequested,
    setAgreeSendMemo,
    agreeSendMemo,
    alertMemoNeed,
    currencies,
    changeForm,
    ignoreChecks,
    currentMarket,
    setCurrentMarket,
    selectedMarket,
    anchorEl,
    setAnchorEl,
    addSnackBar,
  } = props;
  const [currenciesIssuesFound, setCurrenciesIssuesFound] = useState();

  const currencySelected = getCurrencyBySymbol(currencies, currency) || getCurrencyBySymbol(currencies, "BTC");
  const currencySelectedObj = new Currency(currencySelected);
  const depositInfo = currencySelectedObj.getDepositInfoByNetwork(network);
  const symbolsCurrencyList = currencyList.map((currency) => currency.symbol);
  const hasNetworkLoaded = !isNilOrEmpty(depositInfo);

  let memo = "";
  let  walletAddress = "";

  if (wallet['address'] && typeof wallet['address'] === "string" && wallet['address'].includes("|")) {
    const addressParts = getWithMemoAddressParts(wallet['address']);
    walletAddress = addressParts.address;
    memo = addressParts.memo;
  } else {
    walletAddress = wallet['address'];
  }
  const qrcodeUrl = walletAddress;
  const { firebaseCurrenciesIssues } = FIREBASE_CURRENCIES_ISSUES;

  useEffect(() => {
    if (firebaseCurrenciesIssues) {
      const currenciesIssuesData = getCurrencyIssuesFoundBySymbolAndScreen(
        firebaseCurrenciesIssues, 
        currencySelected.symbol, 
        'DEPOSIT'
      );
      const currenciesIssues = 
        currenciesIssuesData && 
        currenciesIssuesData.map(CurrencyIssue.fromJson);
      setCurrenciesIssuesFound(currenciesIssues);
    }
  }, [firebaseCurrenciesIssues]);

  useEffect(() => {
    const selectedMarketIsCurrentMarket = selectedMarket === currentMarket
    if (!selectedMarketIsCurrentMarket) {
      setWalletRequested(false);
      setNetwork(currencySelected, changeForm);
      setCurrentMarket(selectedMarket)
      setWallet('');
      setCopied(false);

    }
  }, [selectedMarket, currentMarket]);

  useEffect(()=>{
    if (blockchainOptions.length !== 0) {
      const networkValues = blockchainOptions.find(
        (networkObject) => networkObject.value === network
      );
      setBlockchainLabel(networkValues);
    }
  }, [blockchainOptions, network]);

  useEffect(() => {
    setWallet('');
    setCopied(false);
  }, [network]);


  const withWallet = R.not(isNilOrEmpty(walletAddress));
  return hasNetworkLoaded ? (
    <div className={classes.root}>
      {!hideCurrencyRadio && (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "24px",
            }}
          >
            <Typography variant="body2">
              {t("transactions.chooseCurrency")}
            </Typography>
          </div>
          <Select
            value={currencySelected}
            customHandleClick={(e) => {
              anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget);
            }}
            placeholder={currencySelected.symbol}
            getLabel={(option) => (
              <SelectItemChip
                itemName={option.symbol}
                currency={option}
                itemCode={option}
              />
            )}
          />
        </React.Fragment>
      )}
      {!hideCurrencySelect && (
        <React.Fragment>
          <div>
            <Typography className={classes.networkHeader} variant="body2">
              {t("transactions.networkBlockchain")}
            </Typography>
          </div>

          <Select
            value={network}
            options={blockchainOptions}
            placeholder={t("forms.deposit.select_bank")}
            onChange={handleBlockchainChange}
            getLabel={(option) => {
              if (option.label) return option.label;

              const networkDetails = blockchainOptions.find(
                (networkObject) =>
                  networkObject.value === option ||
                  networkObject.blockchain === option
              );
              return networkDetails ? networkDetails.label : "";
            }}
          />
        </React.Fragment>
      )}

      {currenciesIssuesFound &&
        currenciesIssuesFound.map((currencyIssue, index) => {
          return(
          <Grid
            key={`currencyIssueDeposit-${index}`}
            container
            xs={12}
            justify="center"
            alignItems="center"
            className={classes.currencyIssueDeposit}
          >
            <Typography variant="body2">{currencyIssue.title}</Typography>
            <InfoTooltip
              wrapperRootHeader={true}
              title={currencyIssue.description.join(" ")}
            />
          </Grid>
        )})}

      <FieldRow>
        {!hideBalance && (
          <TextField
            label={t("transactions.actualBalance")}
            value={Number(balance[currency]).toFixed(8)}
            disabled
          />
        )}
      </FieldRow>

      {symbolsCurrencyList.includes(currency) &&
        depositInfo &&
        depositInfo.minAmount !== 0 &&
        !ignoreChecks && (
          <FieldRow style={{ marginTop: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  name={currencySelected.name}
                  checked={minimumCurrencyDeposit}
                  onChange={(event) =>
                    setMinimumCurrencyDeposit(!minimumCurrencyDeposit)
                  }
                />
              }
              label={t("transfers.withdrawal.agreeMinimumDeposit", {
                minimum: depositInfo ? depositInfo.minAmount : 0,
                currency,
              })}
            />
          </FieldRow>
        )}

      {currency === BTC && (
        <FieldRow>
          <FormControlLabel
            control={
              <Checkbox
                name="agreeSendOnlyBTC"
                checked={agreeSendOnlyBTC}
                onChange={(event) => setAgreeSendOnlyBTC(!agreeSendOnlyBTC)}
              />
            }
            label={t("transfers.withdrawal.agreeSendOnlyBTC")}
          />
        </FieldRow>
      )}
      {currency === USDC && (
        <FieldRow>
          <FormControlLabel
            control={
              <Checkbox
                name="agreeUSDCOnlySOL"
                checked={agreeUSDCOnlySOL}
                onChange={(event) => setAgreeUSDCOnlySOL(!agreeUSDCOnlySOL)}
              />
            }
            label={t("transfers.withdrawal.agreeUSDCOnlySOL", {
              network: blockchainLabel ? blockchainLabel.label : "",
            })}
          />
        </FieldRow>
      )}
      {currency === USDT && (
        <FieldRow>
          <FormControlLabel
            control={
              <Checkbox
                name="agreeUSDTOnlyERC20"
                checked={agreeUSDTOnlyERC20}
                onChange={(event) => setAgreeUSDTOnlyERC20(!agreeUSDTOnlyERC20)}
              />
            }
            label={t("transfers.withdrawal.agreeUSDTOnlyERC20")}
          />
        </FieldRow>
      )}
      {depositInfo && depositInfo.memoEnabled && (
        <FieldRow>
          <FormControlLabel
            control={
              <Checkbox
                name="agreeSendMemo"
                checked={agreeSendMemo}
                onChange={(event) => setAgreeSendMemo(!agreeSendMemo)}
              />
            }
            label={t("transfers.withdrawal.agreeSendMemo")}
          />
        </FieldRow>
      )}

      <React.Fragment>
        {withWallet &&
          shouldAllowShowWallet(
            props,
            ignoreChecks,
            currencySelectedObj.symbol,
            depositInfo ? depositInfo.minAmount : 0,
            depositInfo ? depositInfo.memoEnabled : false
          ) && (
            <React.Fragment>
              {memo !== "" && (
                <React.Fragment>
                  <FieldRow>
                    <TextField
                      label={
                        <label style={{ color: "red" }}>
                          {t("wallet.myMemoAddress")}
                        </label>
                      }
                      value={memo}
                      disabled
                    />
                  </FieldRow>
                  <QRCode value={memo} />
                  <CopyWallet
                    {...props}
                    label={"wallet.copyMemo"}
                    hideWarning={true}
                    walletAddress={memo}
                  />
                </React.Fragment>
              )}
              <FieldRow>
                {currency === BTC ? (
                  <TextField
                    onCopy={onCopyWalletAddress}
                    label={t("wallet.myWalletAddressBTC")}
                    value={walletAddress}
                    disabled
                  />
                ) : (
                  <TextField
                    onCopy={onCopyWalletAddress}
                    label={t("wallet.myWalletAddress")}
                    value={walletAddress}
                    disabled
                  />
                )}
              </FieldRow>
              <QRCode value={qrcodeUrl} />
              <CopyWallet
                {...props}
                label={"wallet.copyAddress"}
                walletAddress={walletAddress}
              />
            </React.Fragment>
          )}
        {!withWallet &&
          shouldAllowShowWallet(
            props,
            ignoreChecks,
            currencySelected.symbol
          ) && (
            <Button
              style={{ margin: 20 }}
              onClick={async () => {
                if (depositInfo.memoEnabled) alertMemoNeed();
                setWalletRequested(true);
                try {
                  const resFetchWalletAddress = await fetchWalletAddress(
                    network,
                    networkInUse
                  );
                  setWallet(resFetchWalletAddress);
                } catch (error) {
                  const { message_cod } = error;
                  addSnackBar({ message: message_cod });
                  setWalletRequested(false);
                }
              }}
              disabled={walletRequested}
              variant="raised"
              color="secondary"
            >
              {walletRequested ? (
                <CircularProgress size={20} />
              ) : (
                t("wallet.requestAddress")
              )}
            </Button>
          )}
      </React.Fragment>
      {withWallet && copied ? (
        <span className={classes.walletCopiedState}>{t("wallet.copied")}</span>
      ) : null}

      {(currency === ETH || currency === "CBRL" || currency === "BRZ") &&
        network !== SOL && (
          <FieldRow>
            <Typography
              className={classes.warningMessage}
              variant="body2"
            >{`• ${t("info.supportBSCandBTCNetwork")}`}</Typography>
          </FieldRow>
        )}
      {isOnlyBlockchainBep20 && (
        <FieldRow>
          <Typography
            className={classes.warningMessage}
            variant="body2"
          >{`• ${t("info.supportOnlyBep")}`}</Typography>
        </FieldRow>
      )}
      {isOnlyBlockchainErc20 && (
        <FieldRow>
          <Typography
            className={classes.warningMessage}
            variant="body2"
          >{`• ${t("info.supportOnlyErc")}`}</Typography>
        </FieldRow>
      )}
      {isOnlyBlockchainAda && (
        <FieldRow>
          <Typography
            className={classes.warningMessage}
            variant="body2"
          >{`• ${t("info.supportOnlyCardano")}`}</Typography>
        </FieldRow>
      )}
      {isOnlyBlockchainLuna && (
        <FieldRow>
          <Typography
            className={classes.warningMessage}
            variant="body2"
          >{`• ${t("info.supportOnlyLuna")}`}</Typography>
        </FieldRow>
      )}
      {(isOnlyBlockchainSol || (currency === "BRZ" && network == SOL)) && (
        <FieldRow>
          <Typography
            className={classes.warningMessage}
            variant="body2"
          >{`• ${t("info.supportOnlySol", {
            network: blockchainLabel ? blockchainLabel.label : "",
          })}`}</Typography>
        </FieldRow>
      )}
      <FieldRow>
        {limitsDeposit && (
          <LimitsIndicator
            type="deposit"
            limits={limitsDeposit}
            currency={currency}
          />
        )}
      </FieldRow>
    </div>
  ) : (
    <ActivityIndicator />
  );
};

const shouldAllowShowWallet = (
  props,
  ignoreChecks,
  symbol,
  minAmount,
  memoEnabled,

) => {
  const {
    agreeSendMemo,
    agreeSendOnlyBTC,
    agreeUSDTOnlyERC20,
    agreeUSDCOnlySOL,
    minimumCurrencyDeposit,
    currencyList,
    currency,
  } = props;

  const symbolsCurrency = currencyList.map((item) => item.symbol);
  
  if (!symbolsCurrency.includes(currency) || ignoreChecks) return true;

  if (memoEnabled)
    return minAmount > 0
      ? agreeSendMemo && minimumCurrencyDeposit
      : agreeSendMemo;

  switch (symbol) {
    case BTC:
      return agreeSendOnlyBTC && minimumCurrencyDeposit;
    case USDT:
      return agreeUSDTOnlyERC20 && minimumCurrencyDeposit;
    case USDC:
      return agreeUSDCOnlySOL && minimumCurrencyDeposit;
    default:
      if (minAmount !== 0) return minimumCurrencyDeposit;
      else return true;
  }
};

ReceiveCoins.defaultProps = {
  walletAddress: null,
};

const mapStateToProps = (state, props) => {
  const selector = formValueSelector("receiveCoinsForm");
  const currency = props.hideCurrencyRadio
    ? props.currency
    : selector(state, "currency") || BTC;
  const network = selector(state, "network") || BTC;
  return {
    balance: state.user.balance,
    userName: state.user.profile.name,
    limits: state.user.limits,
    currency,
    selectedMarket: state.market.selectedMarket,
    network,
    fees: state.user.fees,
    kycLevel: state.user.profile.kyc_level,
    taxID: state.user.profile.tax_id,
    currencies: state.currencies.currencies,
    anchorEl: state.layout.appBar.anchorEl,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addSnackBar: (message) => dispatch(addSnack(message)),
  setAnchorEl: (anchorEl) =>
    dispatch(
      handleAppBarMenu(anchorEl, true, {
        hasStableCoin: true,
        stableCoinAction: "deposit",
      })
    ),
  setMarket: (market) => dispatch(setSelectedMarket(market)),
  changeForm: (formName, field, value) =>
    dispatch(change(formName, field, value)),
  alertUSDTDeposit: () => {
    dispatch(
      addDialog({
        title: "info.depositUSDTWarning",
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        availableChoices: [
          {
            label: "common.understood",
            actionToTake: "",
            color: "secondary",
            variant: "raised",
          },
        ],
      })
    );
  },
  alertMemoNeed: () => {
    dispatch(
      addDialog({
        title: "info.alertMemoNeed",
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        availableChoices: [
          {
            label: "common.understood",
            actionToTake: "",
            color: "secondary",
            variant: "raised",
          },
        ],
      })
    );
  },
  warningCripto: (info) => {
    dispatch(
      addDialog({
        title: info,
        availableChoices: [
          {
            label: "common.understood",
            actionToTake: "",
            color: "secondary",
            variant: "raised",
          },
        ],
      })
    );
  },
  alertDifferenceCopyWalletAddress: (addresses) => {
    dispatch(
      addDialog({
        title: "common.attention",
        centerTitle: true,
        componentProps: addresses,
        renderComponent: "alertDifferenceCopyWalletAddress",
        disableBackdropClick: true,
        availableChoices: [
          {
            label: "common.understood",
            actionToTake: "",
            color: "secondary",
            variant: "raised",
          },
        ],
      })
    );
  },
});

const setNetwork = (currency, changeForm) => {
  const blockchainOptions = new Currency(currency).getDepositBlockchainOptions;
  if (blockchainOptions.length > 0) {
    changeForm("receiveCoinsForm", "network", blockchainOptions[0].value);
  }
};

const enhance = compose(
  translate(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withState('copied', 'setCopied', false),
  withState('agreeUSDTOnlyERC20', 'setAgreeUSDTOnlyERC20', false),
  withState('agreeUSDCOnlySOL', 'setAgreeUSDCOnlySOL', false),
  withState('agreeSendOnlyBTC', 'setAgreeSendOnlyBTC', false),
  withState('agreeSendMemo', 'setAgreeSendMemo', false),
  withState('minimumCurrencyDeposit', 'setMinimumCurrencyDeposit', false),
  withState('currency', 'setCurrency', 'BTC'),
  withState('walletRequested', 'setWalletRequested', false),
  withState('blockchainLabel', 'setBlockchainLabel', ''),
  withState('loadedCurrencies', 'setLoadedCurrencies', false ),
  withState('currentMarket', 'setCurrentMarket', ''),
  withState('wallet', 'setWallet', []),
    reduxForm({
    form: 'receiveCoinsForm',
    initialValues: { network: BTC }
  }),

  withProps((props) => {
    const {
      selectedMarket,
      changeForm,
      destinationCurrency,
      networkInUse,
      network,
      limits,
      setWalletRequested,
      currencies,
    } = props;
    const extractedSelectedCurrency = selectedMarket.split("-")[0];
    const currency = selectedMarket
      ? extractedSelectedCurrency
      : props.currency;
    const currencyList = getCriptoCurrencies(currencies);
    const currencyConfig = getCurrencyBySymbol(currencyList, currency);
    const currencyConfigObj = new Currency(currencyConfig);
    const isOnlyBlockchainSol =
      currencyConfigObj.hasWithdrawalNetwork("SOL") &&
      currencyConfigObj.withdrawal.length === 1;
    const isOnlyBlockchainAda =
      currencyConfigObj.hasWithdrawalNetwork("ADA") &&
      currencyConfigObj.withdrawal.length === 1;
    const isOnlyBlockchainLuna =
      currencyConfigObj.hasWithdrawalNetwork("TERRA") &&
      currencyConfigObj.withdrawal.length === 1;
    const isOnlyBlockchainBep20 =
      currencyConfigObj.hasWithdrawalNetwork("BSC") &&
      currencyConfigObj.withdrawal.length === 1;
    const isOnlyBlockchainErc20 =
      currencyConfigObj.hasWithdrawalNetwork("ETH") &&
      currencyConfigObj.withdrawal.length === 1;

    if (destinationCurrency && networkInUse) {
      setWalletRequested(false);
      changeForm("receiveCoinsForm", "network", networkInUse);
      return {
        currency: destinationCurrency,
        currencyWalletAddress: networkInUse,
        currencyList,
        isOnlyBlockchainSol,
        isOnlyBlockchainAda,
        isOnlyBlockchainLuna,
        isOnlyBlockchainBep20,
        isOnlyBlockchainErc20,
        currencyConfig,
      };
    }

    changeForm("receiveCoinsForm", "currency", currency);
    const currencyLimits = limits[currency];
    const [total, used, available_used] = R.props(
      ["total_deposit", "used_deposit", "available_deposit"],
      currencyLimits
    );
    const currencyWalletAddress =
      currency === BTC && network === BSC ? network : currency;

    const blockchainOptions = currencyConfigObj.getDepositBlockchainOptions;

    return {
      currency,
      currencyWalletAddress,
      blockchainOptions,
      limitsDeposit: { total, used, available_used },
      currencyList,
      isOnlyBlockchainSol,
      isOnlyBlockchainAda,
      isOnlyBlockchainLuna,
      isOnlyBlockchainBep20,
      isOnlyBlockchainErc20,
      currencyConfig,
    };
  }),
  withHandlers({
    onCopyWalletAddress:
      ({ wallet, network, alertDifferenceCopyWalletAddress }) =>
      (textFromCopyComponent) => {
        const walletAddress = wallet['address'] && typeof wallet['address'] === "string" && wallet['address'].includes("|")
          ? getWithMemoAddressParts(wallet[network]).address
          : wallet['address'];
        setTimeout(() => {
          alertDifferenceCopyWalletAddress({ walletAddress });
        }, 500);
      },
    handleBlockchainChange:
      ({ changeForm, setWalletRequested }) =>
      (blockchainOptions) => {
        setWalletRequested(false);
        changeForm("receiveCoinsForm", "network", blockchainOptions.value);
      },
    handleCurrencyChange:
      ({
        setCurrency,
        setMarket,
        history,
        setMinimumCurrencyDeposit,
        setWalletRequested,
        changeForm,
        currentMarket
      }) =>
      (currency) => {
        setMinimumCurrencyDeposit(false);
        setWalletRequested(false);
        if (currency.symbol === "BRZ") {
          const nextLocation = {
            pathname: "/bankTransfers/deposit",
            state: { stableCoinOp: true },
          };
          history.push(nextLocation);
        } else {
          setCurrency(currency.symbol);
          setMarket({market: `${currency.symbol}-BRL`});
        }
        if (currentMarket.split('-')[0] === currency.symbol) {
          setNetwork(currency, changeForm);}
      },
  }),
  lifecycle({
    componentDidMount() {
      const { currencyConfig, changeForm } =
        this.props;
      setNetwork(currencyConfig, changeForm);
    },
  })
);

export default enhance(ReceiveCoins);
