import * as Yup from "yup";
import { compose, withHandlers, withProps, withState } from "recompose";
import { withFormik, yupToFormErrors } from "formik";
import { connect } from "react-redux";
import { formatToBRL } from "../../utils/NumberFormat/moneyFormats";
import {
  CRYPTO_CONVERTION_AMOUNT_TYPES,
  cryptoConvertionAmountTypesToInputNames,
} from "./constants";
import { getInitialToCurrency, getInitialToCurrencySymbol } from "./utils";
import { getCurrencyBySymbol } from "../../models/Currency";
import { handleAppBarMenu, setSelectedMarket, addSnack } from "../../redux/actions";
import getConvertionQuote from "../../services/cbtcService/getConvertionQuote";
import executeConvertionQuote from "../../services/cbtcService/executeConvertionQuote";
import { ConvertionQuoteRequestData } from "../../models/ConvertionQuoteRequestData";
import { MarketInfo } from "../../models/MarketInfo";

const mapStateToProps = (state) => ({
  market: state.market.selectedMarket,
  demoAccount: state.credentials.demoAccount,
  markets: state.currencies.markets,
  balance: state.user.balance,
  currencies: state.currencies.currencies,
  anchorEl: state.layout.appBar.anchorEl,
  ticker: state.ticker,
});

const mapDispatchToProps = (dispatch) => ({
  setAnchorEl: (anchorEl, currencySelectorFilter, extraData) =>
    dispatch(handleAppBarMenu(anchorEl, currencySelectorFilter, extraData)),
  setSelectedMarket: (market) => dispatch(setSelectedMarket(market)),
  showSnack: (snack) => dispatch(addSnack(snack)),
});

const CryptoConverterSchema = Yup.object().shape({
  fromAmount: Yup.number()
    .required("REQUIRED")
    .when(
      ["$minBaseAmountLimit", "$fromCurrencySymbol", "$fromAmountType"],
      (minBaseAmountLimit, fromCurrencySymbol, fromAmountType, schema) => {
        return fromAmountType === CRYPTO_CONVERTION_AMOUNT_TYPES.FROM
          ? schema.min(minBaseAmountLimit, {
              error: "BELOW_MINIMUM_AMOUNT_FIELD",
              min: minBaseAmountLimit,
              symbol: fromCurrencySymbol,
            })
          : schema;
      }
    )
    .when(
      [
        "$maxBaseAmountLimit",
        "$fromAmountType",
        "$balance",
      ],
      (
        maxBaseAmountLimit,
        fromAmountType,
        balance,
        schema
      ) => {
        const isFromAmountType =
          fromAmountType === CRYPTO_CONVERTION_AMOUNT_TYPES.FROM;

        if (isFromAmountType) {
          let errorMessage = "ABOVE_MAX_AMOUNT";
          let maxAmountToUse = maxBaseAmountLimit;

          if (balance < maxBaseAmountLimit) {
            errorMessage = "NOT_ENOUGH_BALANCE";
            maxAmountToUse = balance;
          }

          return schema.max(maxAmountToUse, {
            error: errorMessage,
          });
        } else {
          return schema;
        }
      }
    ),
  volumeAmount: Yup.number()
    .when(
      ["$minQuoteAmountLimit", "$fromAmountType"],
      (minQuoteAmountLimit, fromAmountType, schema) => {
        return fromAmountType === CRYPTO_CONVERTION_AMOUNT_TYPES.VOLUME
          ? schema.min(minQuoteAmountLimit, {
              error: "BELOW_MINIMUM_AMOUNT_FIELD",
              min: formatToBRL(minQuoteAmountLimit),
              symbol: "",
            })
          : schema;
      }
    )
    .when(
      ["$maxQuoteAmountLimit", "$fromAmountType"],
      (maxQuoteAmountLimit, fromAmountType, schema) => {
        return fromAmountType === CRYPTO_CONVERTION_AMOUNT_TYPES.VOLUME
          ? schema.max(maxQuoteAmountLimit, {
              error: "ABOVE_MAX_AMOUNT",
            })
          : schema;
      }
    ),
  toAmount: Yup.number().required("REQUIRED"),
});

const validateFromAmountLimits = (data) => {
  const { isVolumeAmountType, marketInfo, fromCurrencyBalance, amount } = data;
  const minQuoteAmountLimit = marketInfo?.rfqMinQuoteAmount ?? 0;
  const minBaseAmountLimit = marketInfo?.rfqMinBaseAmount ?? 0;
  const maxBaseAmountLimit = marketInfo?.rfqMaxBaseAmount ?? 0;
  const maxQuoteAmountLimit = marketInfo?.rfqMaxQuoteAmount ?? 0;
  const isBalanceEnough = amount <= fromCurrencyBalance;

  const minAmountToUse = isVolumeAmountType ? minQuoteAmountLimit : minBaseAmountLimit;
  const maxAmountToUse = isVolumeAmountType ? maxQuoteAmountLimit : maxBaseAmountLimit;
  const isInLimits = amount >= minAmountToUse && amount <= maxAmountToUse;

  return isVolumeAmountType ? isInLimits : isInLimits && isBalanceEnough;
}

const withCryptoConverter = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState("isRequestingQuote", "setIsRequestingQuote", false),
  withState("showConfirmConvertionDialog", "setShowConfirmConvertionDialog", false),
  withProps(({ currencies, market }) => ({
    initialFromCurrencyData: getCurrencyBySymbol(
      currencies,
      market.split("-")[0]
    ),
    initialToCurrencyData: getInitialToCurrency(
      market.split("-")[0],
      currencies
    ),
    brlCurrencyData: getCurrencyBySymbol(currencies, "BRL"),
  })),
  withFormik({
    enableReinitialize: false,
    mapPropsToValues: ({
      market,
      initialToCurrencyData,
      initialFromCurrencyData,
      brlCurrencyData,
    }) => ({
      fromCurrency: market.split("-")[0],
      toCurrency: getInitialToCurrencySymbol(market.split("-")[0]),
      market: market,
      volumeAmount: (0).toFixed(brlCurrencyData.exhibit_precision),
      fromAmount: (0).toFixed(initialFromCurrencyData.exhibit_precision),
      toAmount: (0).toFixed(initialToCurrencyData.exhibit_precision),
      amountType: CRYPTO_CONVERTION_AMOUNT_TYPES.VOLUME,
      fromAmountType: CRYPTO_CONVERTION_AMOUNT_TYPES.VOLUME,
      quoteId: null,
      expiration: null,
    }),
    validate: (values, props) => {
      const { markets } = props;
      const { fromCurrency, toCurrency } = values;
      const fromCurrencyMarket = `${fromCurrency}-BRL`;
      const marketConfig = new MarketInfo(markets[fromCurrencyMarket]);
      const fromCurrencySymbol = fromCurrency;
      const toCurrencySymbol = toCurrency;
      const minBaseAmountLimit =  marketConfig?.rfqMinBaseAmount ?? 0;
      const minQuoteAmountLimit = marketConfig?.rfqMinQuoteAmount ?? 0;
      const maxBaseAmountLimit = marketConfig?.rfqMaxBaseAmount ?? 0;
      const maxQuoteAmountLimit = marketConfig?.rfqMaxQuoteAmount ?? 0;
      const { fromAmountType } = values;
      const balance = props.balance[fromCurrencySymbol];

      return CryptoConverterSchema.validate(values, {
        abortEarly: false,
        context: {
          balance,
          fromAmountType,
          fromCurrencySymbol,
          minBaseAmountLimit,
          minQuoteAmountLimit,
          fromCurrencySymbol,
          toCurrencySymbol,
          maxBaseAmountLimit,
          maxQuoteAmountLimit,
        },
      }).catch((err) => {
        throw yupToFormErrors(err);
      });
    },
    clearForm: (formikBag) => {
      const { resetForm } = formikBag;
      resetForm();
    },
  }),
  withProps(
    ({
      values,
      setFieldValue,
      setIsRequestingQuote,
      ticker,
      setShowConfirmConvertionDialog,
      balance,
      showSnack,
      setErrors,
    }) => {
      const cancelQuote = () => {
        setFieldValue("quoteId", null);
        setFieldValue("expiration", null);
      };

      const clearFormInputs = () => {
        setFieldValue("volumeAmount", 0);
        setFieldValue("fromAmount", 0);
        setFieldValue("toAmount", 0);
      };

      const resetAllForm = () => {
        cancelQuote();
        clearFormInputs();
      };

      const getConvertionQuoteAndSetValues = async (requestData) => {
        try {
          if (requestData.amount <= 0) {
            cancelQuote();
            setIsRequestingQuote(false);
            return;
          }
          const offer = await getConvertionQuote(requestData);
          const { quoteId, expiration, fromAmount, toAmount, fee } = offer;
          if (
            requestData.amountType !== CRYPTO_CONVERTION_AMOUNT_TYPES.VOLUME
          ) {
            setFieldValue(
              "volumeAmount",
              (ticker[requestData.from]?.last || 0) * fromAmount
            );
          }
          setFieldValue("quoteId", quoteId);
          setFieldValue("expiration", expiration);
          setFieldValue("fromAmount", fromAmount);
          setFieldValue("toAmount", toAmount);
          setFieldValue("fee", fee);
          setIsRequestingQuote(false);
          if (offer.fromAmount > balance[requestData.from]) {
            const notEnoughBalanceError = new Error("NOT_ENOUGH_BALANCE");
            notEnoughBalanceError.message_cod = "NOT_ENOUGH_BALANCE";
            throw notEnoughBalanceError;
          }
          return offer;
        } catch (error) {
          setIsRequestingQuote(false);
          cancelQuote();
          if (error.message_cod) {
            setTimeout(() => {
              setErrors({
                [cryptoConvertionAmountTypesToInputNames[
                  requestData.amountType
                ]]: error.message_cod,
              });
            }, 0);
          }
        }
      };
      return {
        getConvertionQuote: getConvertionQuoteAndSetValues,
        cancelQuote,
        resetAllForm,
        executeConvertionQuote: async () => {
          const { quoteId } = values;
          try {
            setIsRequestingQuote(true);
            await executeConvertionQuote(quoteId);
            setShowConfirmConvertionDialog(false);
            resetAllForm();
          } catch (error) {
            if (error.message_cod) {
              showSnack({ message: error.message_cod });
            } else {
              showSnack({ message: "GENERIC_ERROR" });
            }
          }
          
          setIsRequestingQuote(false);

        },
        clearFormInputs,
        getConvertionQuoteWithLastValues: () => {
          const {
            fromCurrency,
            toCurrency,
            volumeAmount,
            fromAmount,
            toAmount,
            amountType,
          } = values;
          let amountTouse = 0;
          switch (amountType) {
            case CRYPTO_CONVERTION_AMOUNT_TYPES.FROM:
              amountTouse = fromAmount;
              break;
            case CRYPTO_CONVERTION_AMOUNT_TYPES.TO:
              amountTouse = toAmount;
              break;
            case CRYPTO_CONVERTION_AMOUNT_TYPES.VOLUME:
              amountTouse = volumeAmount;
              break;
          }

          const requestData = new ConvertionQuoteRequestData({
            from: fromCurrency,
            to: toCurrency,
            amount: amountTouse,
            amountType: amountType,
          });
          setIsRequestingQuote(true);
          return getConvertionQuoteAndSetValues(requestData);
        },
      };
    }
  ),
  withHandlers({
    handleFromInputChange: (props) => async (amount, debouncedGetQuote) => {
      const {
        setFieldValue,
        values,
        setIsRequestingQuote,
        isRequestingQuote,
        clearFormInputs,
        cancelQuote,
        markets,
        balance,
      } = props;
      const { fromAmountType, fromCurrency, toCurrency, expiration } = values;
      const usingVolumeAmount =
        fromAmountType === CRYPTO_CONVERTION_AMOUNT_TYPES.VOLUME;
      const fieldToChange =
        cryptoConvertionAmountTypesToInputNames[fromAmountType];
      setFieldValue(fieldToChange, amount);
      setFieldValue("amountType", fromAmountType);
      
      const marketKey = `${fromCurrency}-BRL`;
      const marketInfo = new MarketInfo(markets[marketKey]);

      const isInLimits =  validateFromAmountLimits({
        isVolumeAmountType: usingVolumeAmount,
        marketInfo,
        fromCurrencyBalance: balance[fromCurrency],
        amount,
      });

      const amountIsZero = amount <= 0 || amount === undefined;
      if (amountIsZero || !isInLimits) {
        if (amountIsZero) clearFormInputs();
        if (isRequestingQuote || expiration) {
          cancelQuote();
          setIsRequestingQuote(false);
        }
        return;
      }
      setIsRequestingQuote(true);

      const requestData = new ConvertionQuoteRequestData({
        from: fromCurrency,
        to: toCurrency,
        amount,
        amountType: fromAmountType,
      });
      await debouncedGetQuote(requestData);
    },
    handleToInputChange: (props) => (amount, debouncedGetQuote) => {
      const {
        setFieldValue,
        values,
        setIsRequestingQuote,
        clearFormInputs,
        cancelQuote,
      } = props;
      const { fromCurrency, toCurrency } = values;
      const amountIsZero = amount <= 0 || amount === undefined;
      if (amountIsZero) {
        clearFormInputs();
        cancelQuote();
        setIsRequestingQuote(false);
        return;
      }
      setIsRequestingQuote(true);
      setFieldValue("toAmount", amount);
      setFieldValue("amountType", CRYPTO_CONVERTION_AMOUNT_TYPES.TO);
      const requestData = new ConvertionQuoteRequestData({
        from: fromCurrency,
        to: toCurrency,
        amount,
        amountType: CRYPTO_CONVERTION_AMOUNT_TYPES.TO,
      });
      debouncedGetQuote(requestData);
    },
    handleSwitchFromAmountType: (props) => (debouncedGetQuote) => {
      const { setFieldValue, values, cancelQuote, setIsRequestingQuote } =
        props;
      const { fromAmountType, expiration } = values;
      const newFromAmountType =
        fromAmountType === CRYPTO_CONVERTION_AMOUNT_TYPES.VOLUME
          ? CRYPTO_CONVERTION_AMOUNT_TYPES.FROM
          : CRYPTO_CONVERTION_AMOUNT_TYPES.VOLUME;
      setFieldValue("fromAmountType", newFromAmountType);
      setFieldValue("amountType", newFromAmountType);
      if (expiration != null) {
        cancelQuote();
        setIsRequestingQuote(true);
        const requestData = new ConvertionQuoteRequestData({
          from: values.fromCurrency,
          to: values.toCurrency,
          amount:
            values[
              newFromAmountType === CRYPTO_CONVERTION_AMOUNT_TYPES.FROM
                ? "fromAmount"
                : "volumeAmount"
            ],
          amountType: newFromAmountType,
        });
        debouncedGetQuote(requestData);
      }
    },
    handleMaxButtonClick: (props) => (debouncedGetQuote) => {
      const { setFieldValue, values, balance, setIsRequestingQuote } = props;
      const { fromCurrency, fromAmountType, amountType } = values;
      setIsRequestingQuote(true);
      if (fromAmountType === CRYPTO_CONVERTION_AMOUNT_TYPES.VOLUME) {
        setFieldValue("fromAmountType", CRYPTO_CONVERTION_AMOUNT_TYPES.FROM);
      }
      if (amountType !== CRYPTO_CONVERTION_AMOUNT_TYPES.FROM) {
        setFieldValue("amountType", CRYPTO_CONVERTION_AMOUNT_TYPES.FROM);
      }
      setFieldValue("volumeAmount", 0);
      setFieldValue("fromAmount", balance[fromCurrency]);

      const requestData = new ConvertionQuoteRequestData({
        from: fromCurrency,
        to: values.toCurrency,
        amount: balance[fromCurrency],
        amountType: CRYPTO_CONVERTION_AMOUNT_TYPES.FROM,
      });
      debouncedGetQuote(requestData);
    },
  })
);

export default withCryptoConverter;
