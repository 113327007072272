export const getCurrencyIssuesFoundBySymbolAndScreen = (
  currencyIssues,
  currencySymbol,
  operationScreen
) => {
  return currencyIssues.filter(
    (issue) =>
      issue.currencySymbol === currencySymbol &&
      issue.operationScreens?.includes(operationScreen) &&
      typeof issue?.title === "string" &&
      Array.isArray(issue?.description)
  );
};

export const getShowCryptoConverter = (
  showCryptoConverterFirebase,
  isBetaTester
) => {
  return showCryptoConverterFirebase || isBetaTester;
};
