export class MarketInfo {
  constructor({
    base,
    quote,
    is_marketplace,
    rfq_disable,
    rfq_visible,
    orderbook_disable,
    orderbook_visible,
    min_base_amount,
    max_base_amount,
    min_quote_amount,
    max_quote_amount,
    base_precision,
    quote_precision,
    rfq_min_base_amount,
    rfq_max_base_amount,
    rfq_min_quote_amount,
    rfq_max_quote_amount,
  } = {}) {
    this.base = base;
    this.quote = quote;
    this.isMarketplace = is_marketplace;
    this.rfqDisable = rfq_disable;
    this.rfqVisible = rfq_visible;
    this.orderbookDisable = orderbook_disable;
    this.orderbookVisible = orderbook_visible;
    this.minBaseAmount = min_base_amount;
    this.maxBaseAmount = max_base_amount;
    this.minQuoteAmount = min_quote_amount;
    this.maxQuoteAmount = max_quote_amount;
    this.basePrecision = base_precision;
    this.quotePrecision = quote_precision;
    this.rfqMinBaseAmount = rfq_min_base_amount;
    this.rfqMaxBaseAmount = rfq_max_base_amount;
    this.rfqMinQuoteAmount = rfq_min_quote_amount;
    this.rfqMaxQuoteAmount = rfq_max_quote_amount;
  }

  static fromJson(json) {
    return new MarketInfo({
      base: json["base"],
      quote: json["quote"],
      isMarketplace: json["is_marketplace"],
      rfqDisable: json["rfq_disable"],
      rfqVisible: json["rfq_visible"],
      orderbookDisable: json["orderbook_disable"],
      orderbookVisible: json["orderbook_visible"],
      minBaseAmount: json["min_base_amount"],
      maxBaseAmount: json["max_base_amount"],
      minQuoteAmount: json["min_quote_amount"],
      maxQuoteAmount: json["max_quote_amount"],
      basePrecision: json["base_precision"],
      quotePrecision: json["quote_precision"],
      rfqMinBaseAmount: json["rfq_min_base_amount"],
      rfqMaxBaseAmount: json["rfq_max_base_amount"],
      rfqMinQuoteAmount: json["rfq_min_quote_amount"],
      rfqMaxQuoteAmount: json["rfq_max_quote_amount"],
    });
  }

  toJson() {
    return {
      base: this.base,
      quote: this.quote,
      is_marketplace: this.isMarketplace,
      rfq_disable: this.rfqDisable,
      rfq_visible: this.rfqVisible,
      orderbook_disable: this.orderbookDisable,
      orderbook_visible: this.orderbookVisible,
      min_base_amount: this.minBaseAmount,
      max_base_amount: this.maxBaseAmount,
      min_quote_amount: this.minQuoteAmount,
      max_quote_amount: this.maxQuoteAmount,
      base_precision: this.basePrecision,
      quote_precision: this.quotePrecision,
      rfq_min_base_amount: this.rfqMinBaseAmount,
      rfq_max_base_amount: this.rfqMaxBaseAmount,
      rfq_min_quote_amount: this.rfqMinQuoteAmount,
      rfq_max_quote_amount: this.rfqMaxQuoteAmount,
    };
  }

  get chartTickerSymbol() {
    return `${this.base}${this.quote}`;
  }
}

export const getVisibleMarkets = (markets) => {
  return Object.values(markets).filter((market) => {
    return market.rfq_visible || market.orderbook_visible;
  });
};
