import {
  compose, defaultProps, lifecycle, withProps, withState
} from 'recompose';
import * as R from 'ramda';
import { connect } from 'react-redux';
import {
  addDialog,
  downloadCompleteIR,
  downloadSimpleIR,
  fetchExtract,
  handleAppBarMenu,
} from '../../redux/actions';

import { AVAILABLE_OPERATIONS } from '../../config';
import { transactionTypes } from '../../utils/constants';

import Extract from './Extract';
import { getVisibleMarkets } from '../../models/MarketInfo';

export const typesToNotFetch = [
  transactionTypes.CUSTODY_TRANSFER,
  transactionTypes.CONTRACT_TURBOBUY_FEE,
  transactionTypes.CONTRACT_TURBOBUY,
];

const formRequest = {
  status: "COMPLETE",
};
const mapStateToProps = ({ user: { extract: { extracts }, profile }, currencies, layout }) => ({
  extracts,
  kycLevel: profile.kyc_level,
  taxID: profile.tax_id,
  currencies: currencies.currencies,
  markets: currencies.markets,
  anchorEl: layout.appBar.anchorEl,
});

const mapDispatchToProps = dispatch => ({
  setAnchorEl: (anchorEl, currencySelectorFilter, extraData) =>
  dispatch(handleAppBarMenu(anchorEl, currencySelectorFilter, extraData)),
  fetchExtractData: (formRequest) => dispatch(fetchExtract(formRequest)),
  showDownloadIRDialog: () => dispatch(addDialog({
    title: 'screens.extract.titleIR',
    availableChoices: [
      {
        label: 'screens.extract.completeIR',
        actionToTake: 'downloadCompleteIR',
        color: 'secondary',
        variant: 'raised'
      },
      {
        label: 'screens.extract.simplifiedIR',
        actionToTake: 'downloadSimpleIR',
        color: 'secondary',
        variant: 'raised'
      },

      {
        label: 'common.cancel',
        actionToTake: 'exit'
      }
    ],
    actionsToTake: {
      downloadCompleteIR,
      downloadSimpleIR
    },
  }))
})

const filterExtracts = (extracts) => {
  const extractsFiltered = extracts.filter(
    (e) => e.status !== "CANCELED" && !typesToNotFetch.includes(e.type)
  );
  const concludedExtracts = R.filter(R.prop("concluded"), extractsFiltered);
  return concludedExtracts;
};

const enhance = compose(
  defaultProps({
    extracts: [],
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ extracts, markets }) => {
    let extractOptions = ["ALL", "BRL"];
    const availableMarkets = getVisibleMarkets(markets);
    const extractOperations = AVAILABLE_OPERATIONS;
    const visibleMarketsSymbol = availableMarkets.map(market => market.base);
    extractOptions = extractOptions.concat(visibleMarketsSymbol);
    

    if (extracts) {
      const extractsFiltered = filterExtracts(extracts);
     
      return {
        extracts: extractsFiltered,
        extractOperations: extractOperations,
        extractOptions: extractOptions,
      };
    }
    return {extracts: [], extractOperations: extractOperations, extractOptions: extractOptions};
  }),
  withState('open', 'setIsOpen', false ),
  withState('formValues', 'setFormValues', null),

  lifecycle({
    componentDidMount() {
      const {
        fetchExtractData
      } = this.props;
      fetchExtractData(formRequest);
    },
  }),
);

export default enhance(Extract);
